import React, { PropsWithChildren } from "react";
import styled from "@emotion/styled";
import { GhostButton } from "../Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileImage, faMagic } from "@fortawesome/free-solid-svg-icons";
import { css } from "@emotion/react";
import { useGetDurationBetweenContext } from "../../hooks/useGetDurationBetweenContext";
import { TimelineItem } from "../../service/types";
import {constructImageUrl} from "../../helpers/image"

export const Picture = ({
  index,
  item,
  width=300,
  height=200,
}: {
  index: number;
  item: TimelineItem;
  width?: number;
  height?: number;
}) => {
  const { getDurationBetween } = useGetDurationBetweenContext();
  const durationBetween = getDurationBetween(index);
  // TODO add ability to edit photos on the fly
  const imagePath = constructImageUrl(item.publicId as string, {
    width,
    height,
    // gravity:"faces",
    // crop:"fill"
  })

  return <img src={imagePath} alt={durationBetween}/>
};

export const EmptyPicture = styled(
  ({
    isLooped,
    isGap,
    isInvalid,
    ...props
  }: PropsWithChildren<{
    isInvalid?: boolean;
    isGap?: boolean;
    isLooped?: boolean;
  }>) => (
    <div {...props}>
      <FontAwesomeIcon icon={isLooped ? faMagic : faFileImage} />
    </div>
  ),
)`
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(215, 210, 184, 0.5);
  border: 0.5rem solid rgba(212, 210, 196, 1);
  color: rgba(0, 0, 0, 0.5);
  width: 100%;

  ${({ isLooped }) => isLooped && css``}

  ${({ isGap, isInvalid }) =>
    (isGap || isInvalid) &&
    css`
      color: rgba(235, 106, 110, 0.5);
    `}

  ${({ isGap, isInvalid }) =>
    isGap &&
    isInvalid &&
    css`
      color: #f64d1c;
      border-color: rgba(235, 106, 110, 0.125);
    `}
`;

export const RemoveButton = styled(GhostButton)`
  margin-top: auto;
  color: #d61e12;

  &:hover {
    color: #ff9677;
  }
  &:active {
    color: #cd4c27;
  }
  &:disabled {
    color: #cfcfcf;
  }
`;
