import React, { PropsWithChildren, useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useExtendedTimelineMetrics } from "../../helpers/getTimelineGapMetrics";
import { useIsMutating, useMutation } from "@tanstack/react-query";
import { projectService } from "../../service/projectService";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { rem } from "polished";
import { Button, ButtonRegular, CtaButton } from "../Button";
import Modal from "react-modal";
import { H3, P } from "../Typography";
import { useTimelineContext } from "../../hooks/useTimelineContext";
import { HEADER_HEIGHT } from "../../helpers/constants";

Modal.setAppElement("#root");

const scrollToAnchor = (id: string) => {
  if (!id) return;

  try {
    const element = document.getElementById(id);
    if (element) {
      const headerOffset = 4 * 16;

      const elementPosition = element.offsetTop;
      const offsetPosition = elementPosition - headerOffset;

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
  } catch (err) {
    // querySelector will throw an error if the hash begins with a number
    // or contains a period. This is protected against now by safeSlugify
    // however previous links may be in the wild.
    // eslint-disable-next-line no-console
    console.warn(`Attempted to scroll to invalid id: ${id}`, err);
  }
};

export const BuildButton = () => {
  const navigate = useNavigate();
  const { isValid, fistInvalidId } = useExtendedTimelineMetrics();
  const { project, isDirty, setSubmittedAt } = useTimelineContext();
  const isMutating = useIsMutating();

  const renderProject = useMutation(projectService.patchRender);

  const onRender = ({ projectId }: { projectId: string }) => {
    renderProject.mutate({ projectId });
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = useCallback(() => setIsModalOpen(true), []);
  const closeModal = useCallback(() => setIsModalOpen(false), []);

  const handleClick = () => {
    if (isValid) {
      openModal();

      return;
    }

    setSubmittedAt?.(new Date().toISOString());

    scrollToAnchor(fistInvalidId);
  };

  const handleFinalize = () => {
    onRender({ projectId: project.id });

    return navigate("/in-progress");
  };

  return (
    <>
      <FinalizeButton
        disabled={isValid && (isDirty || isMutating > 0)}
        isValid={isValid}
        onClick={handleClick}
      >
        Finalize slideshow
      </FinalizeButton>

      <StyledModal
        isOpen={isModalOpen}
        contentLabel="Are you ready to finalize your slideshow?"
        onRequestClose={closeModal}
      >
        <Header>
          <H3>Are you ready to finalize your slideshow?</H3>
        </Header>
        <Main>
          <Logo />
          <P>
            Finalizing your slideshow takes 30-60 minutes to fully render all
            photos. After this process, you'll have the ability to edit, if
            needed.
          </P>
        </Main>

        <Footer>
          <ButtonRegular onClick={closeModal}>Not Yet</ButtonRegular>
          <Button onClick={handleFinalize}>Finalize</Button>
        </Footer>
      </StyledModal>
    </>
  );
};

const FinalizeButton = styled(CtaButton)<{ isValid?: boolean }>`
  padding: 0.375rem;

  ${({ isValid }) =>
    !isValid &&
    css`
      background-color: #ffb5a0;
    `}
`;

const Header = styled.header`
  text-align: center;
`;

const Main = styled.main`
  display: flex;
  flex-direction: column;
  margin: auto 0;

  font-family: National-Book, sans-serif;
`;

const Logo = styled((props: PropsWithChildren<unknown>) => (
  <img
    src={"/img/Wait_01.png"}
    alt={"Songfinch slideshow finalization"}
    {...props}
  />
))`
  display: none;
  justify-content: center;
  align-items: center;
  width: 10rem;
  height: 10rem;
  margin: 0 auto;

  @media screen and (min-width: ${rem(320)}) and (min-height: ${rem(540)}) {
    display: flex;
  }

  @media screen and (min-width: ${rem(375)}) and (min-height: ${rem(667)}) {
    width: 15rem;
    height: 15rem;
  }
`;

const Footer = styled.footer`
  display: flex;
  column-gap: 1rem;
  row-gap: 1rem;
  justify-content: flex-end;

  button {
    line-height: 1.5rem;
  }
`;

const StyledModal = styled(Modal)`
  position: absolute;
  display: flex;
  flex-direction: column;
  border: 1px solid #000000;
  background: white;
  overflow: auto;
  border-radius: 0;
  outline: none;
  padding: 2rem;
  margin: ${HEADER_HEIGHT + 1}rem auto 0 auto;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  transform: translate(-50%, -50%);
  footer {
    margin-top: 2rem;
  }

  @media screen and (min-width: ${rem(767)}) and (min-height: ${rem(767)}) {
    max-width: ${rem(600)};
    max-height: ${rem(480)};
    margin: auto;
  }
`;
