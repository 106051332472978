export const isDev = process.env.NODE_ENV === "development";
export const SECTION_LENGTH_IN_SECONDS = 6;

export enum ProjectState {
  created = "CREATED",
  updated = "UPDATED",
  inprogress = "IN_PROGRESS",
  released = "RELEASED",
  failed = "FAILED",
  locked = "LOCKED",
  disabled = "DISABLED",
}

export const HEADER_HEIGHT = 3; // rem
