import React, { PropsWithChildren } from "react";
import styled from "@emotion/styled";
import { rem } from "polished";
import { Button } from "../components/Button";
import { Header } from "../components/Header";
import { Footer } from "../components/Footer";
import { A, H1, H2, H3, H4, P } from "../components/Typography";

const ReturnButton = styled((props: PropsWithChildren<any>) => (
  <Button {...props} />
))`
  margin: 2rem auto 3rem auto;
  display: flex;
  justify-content: center;
  color: white;
  padding: 1rem;
  font-size: 1rem;
  text-decoration: none;

  @media screen and (min-width: ${rem(1024)}) {
    font-size: 2rem;
    margin: 3rem auto 4rem auto;
  }
`;

ReturnButton.defaultProps = {
  children: "Return to dashboard",
  as: "a",
  href: process.env.REACT_APP_DASHBOARD_URL,
};

const BaseFooter = styled.footer`
  font-family: National-SemiBold, sans-serif;
  text-align: center;
  font-size: 1rem;

  @media screen and (min-width: ${rem(1024)}) {
    font-size: 1.5rem;
  }
`;

const ContactUsDefaultProps = {
  Footer: BaseFooter,
  A,
};

const ContactUs = ({ Footer, A }: typeof ContactUsDefaultProps) => (
  <Footer>
    Feel free to <A href="https://www.songfinch.com/contact">contact us</A> if
    you continue to experience an error.
  </Footer>
);

ContactUs.defaultProps = ContactUsDefaultProps;

const defaultProps = {
  Header: (props: PropsWithChildren<any>) => (
    <Header
      {...props}
      Wrapper={styled(Header.defaultProps.Wrapper)`
        @media screen and (min-width: ${rem(1024)}) {
          max-width: ${rem(800)};
        }
      `}
    />
  ),
  Wrapper: styled.main`
    margin: 1rem 2rem;
    @media screen and (min-width: ${rem(1024)}) {
      margin: 1rem auto;
      max-width: ${rem(800)};
    }
  `,
  H1,
  H2,
  H3,
  H4,
  A,
  Footer: BaseFooter,
  ContactUs,

  LargeLogo: styled((props: PropsWithChildren<any>) => (
    <img src={"/songfinch-logo-large.jpg"} alt={"Songfinch logo"} {...props} />
  ))`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 15rem;
    height: 15rem;
    margin: 0 auto;

    @media screen and (min-width: ${rem(1024)}) {
      width: 30rem;
      height: 30rem;
    }
  `,

  ReturnButton,
  PageFooter: (props: PropsWithChildren<any>) => (
    <Footer
      {...props}
      Content={styled(Footer.defaultProps.Content)`
        @media screen and (min-width: ${rem(1024)}) {
          max-width: ${rem(800)};
        }
      `}
    />
  ),
};

export type Props = typeof defaultProps;

export const BasePage = ({
  Header,
  H1,
  H2,
  A,
  Wrapper,
  Footer,
  LargeLogo,
  ReturnButton,
  PageFooter,
}: Props) => (
  <>
    <Header />

    <Wrapper>
      <LargeLogo />

      <H1>We are crafting your video...</H1>

      <Footer>
        <P>
          While some videos take seconds to edit, other need more time. You can
          close this tab, we will send you an email once your video is ready
        </P>

        <ReturnButton />
      </Footer>
    </Wrapper>

    <PageFooter />
  </>
);

BasePage.defaultProps = defaultProps;
