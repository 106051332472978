import {
  AppstoreAddOutlined,
  BugOutlined,
  CheckOutlined,
  FlagOutlined,
  LockOutlined,
  PushpinOutlined,
  QuestionOutlined,
} from "@ant-design/icons";
import { ProjectState } from "./constants";

type IconType =
  | typeof FlagOutlined
  | typeof AppstoreAddOutlined
  | typeof PushpinOutlined
  | typeof CheckOutlined
  | typeof BugOutlined
  | typeof LockOutlined
  | typeof LockOutlined
  | typeof QuestionOutlined;
export const ProjectStateIcons: Record<string, IconType> = {
  [ProjectState.created]: FlagOutlined,
  [ProjectState.updated]: AppstoreAddOutlined,
  [ProjectState.inprogress]: PushpinOutlined,
  [ProjectState.released]: CheckOutlined,
  [ProjectState.failed]: BugOutlined,
  [ProjectState.locked]: LockOutlined,
  [ProjectState.disabled]: LockOutlined,
};
export const getProjectStateIcon = (state: string): IconType =>
  ProjectStateIcons[state] ?? QuestionOutlined;
