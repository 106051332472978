import React from "react";
import styled from "@emotion/styled";
import { rem } from "polished";
import { Link } from "react-router-dom";
import { isAdminTokenValid } from "../helpers/token";
import { Button } from "./Button";
import { HEADER_HEIGHT, ProjectState } from "../helpers/constants";
import { getProjectStateIcon } from "../helpers/projectStateIcons";

const defaultProps = {
  isMutating: 0,
  Header: styled.header`
    height: ${HEADER_HEIGHT}rem;
    width: 100%;
    background: #f2f0e6;
    z-index: 100;

    padding: 0 0.5rem;

    @media screen and (min-width: ${rem(768)}) {
      padding: 0 1rem;
    }

    @media screen and (min-width: ${rem(1200)}) {
      padding: 1rem 0;
    }
  `,
  Wrapper: styled.div`
    display: flex;

    @media screen and (min-width: ${rem(1200)}) {
      margin: auto;
      max-width: ${rem(1024)};
    }
  `,
  Logo: styled.img`
    height: 2rem;
    margin: 0.5rem 1rem;
  `,
  ResponsiveText: styled.span`
    display: none;

    @media screen and (min-width: ${rem(540)}) {
      display: inline;
    }
  `,
};

const logoAlt = "Unique Gifts by Songfinch";
const logoSrc = "/Songfinch_Identity_Horizontal_RGB_BridgeBlack.png";

type Props = Readonly<
  typeof defaultProps &
    Readonly<{
      projectState?: ProjectState;
      isValid?: boolean;
      handleClick?: () => void;
    }>
>;
type Children = (props: Props) => JSX.Element;

export const Header = ({
  children,
  ...props
}: { children: Children } & Props) => {
  return children({ ...props });
};

Header.defaultProps = {
  ...defaultProps,
  children: ({ Header, Wrapper, Logo }: Props) => {
    const isAdmin = isAdminTokenValid();

    return (
      <>
        <Header>
          <Wrapper>
            {isAdmin && (
              <Link to={"/admin"}>
                <Logo alt={logoAlt} src={logoSrc} />
              </Link>
            )}

            {!isAdmin && <Logo alt={logoAlt} src={logoSrc} />}
          </Wrapper>
        </Header>
      </>
    );
  },
};

export const HeaderWithBackToDashboardButton = ({
  children,
  ...props
}: { children: Children } & Props) => {
  return children(props);
};

HeaderWithBackToDashboardButton.defaultProps = {
  ...Header.defaultProps,
  children: ({ Header, Wrapper, Logo, projectState }: Props) => {
    const isAdmin = isAdminTokenValid();

    const Icon = getProjectStateIcon(projectState as string);

    return (
      <>
        <Header>
          <Wrapper>
            {isAdmin && (
              <Link to={"/admin"}>
                <Logo alt={logoAlt} src={logoSrc} />
              </Link>
            )}

            {!isAdmin && <Logo alt={logoAlt} src={logoSrc} />}

            <Right>
              {isAdmin && (
                <>
                  <InfoBox>User: Admin</InfoBox>{" "}
                  <InfoBox>
                    State: <Icon /> {projectState?.toLowerCase()}
                  </InfoBox>
                </>
              )}
              <Button
                onClick={() =>
                  window.location.assign(
                    process.env.REACT_APP_DASHBOARD_URL ?? "/",
                  )
                }
              >
                Start your song
              </Button>
            </Right>
          </Wrapper>
        </Header>
      </>
    );
  },
};

const Right = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
  column-gap: 1rem;
  row-gap: 1rem;
`;

const InfoBox = styled.div`
  border: 1px solid #000000;
  background: white;
  color: #000000;
  border-radius: 0;
  padding: 0.0375rem 0.375rem;
  align-items: baseline;
  display: none;
  justify-content: space-between;

  @media screen and (min-width: ${rem(768)}) {
    display: flex;
  }
`;
