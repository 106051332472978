import { Content } from "./AdminTable";
import { LockOutlined, ToolOutlined, UnlockOutlined } from "@ant-design/icons";
import React from "react";
import { addHours } from "date-fns";
import { ProjectState } from "../helpers/constants";
import { Project } from "../service/types";

type Props = Readonly<{
  project: Project | Content;
  onLock: () => void;
  onUnlock: () => void;
  onFix: () => void;
}>;

export const LockUnlockFixButton = ({
  project,
  onLock,
  onUnlock,
  onFix,
}: Props) => {
  const canBeFixed =
    project.state === ProjectState.inprogress &&
    addHours(new Date(project.updatedAt), 3) < new Date();

  return (
    <>
      {project.state === ProjectState.locked && (
        <UnlockOutlined title="Unlock" onClick={onUnlock} />
      )}

      {project.state !== ProjectState.inprogress &&
        project.state !== ProjectState.locked && (
          <LockOutlined title="Lock" onClick={onLock} />
        )}

      {canBeFixed && <ToolOutlined title="Fix" onClick={onFix} />}
    </>
  );
};
