import styled from "@emotion/styled";
import {rem} from "polished";

export const H1 = styled.h1`
  font-family: National-Bold, sans-serif;
  font-weight: 700;
  text-align: center;
  font-size: ${rem(40)};
  line-height: ${rem(40)};

  @media screen and (min-width: ${rem(768)}) {
    font-size: ${rem(48)};
    line-height: ${rem(56)};
  }
`;
export const H2 = styled.h2`
  font-family: National-SemiBold, sans-serif;
  font-weight: 600;
  text-align: center;
  font-size: ${rem(40)};
  line-height: ${rem(40)};

  @media screen and (min-width: ${rem(768)}) {
    font-size: ${rem(48)};
    line-height: ${rem(56)};
  }
`;
export const H3 = styled.h3`
  font-family: National-SemiBold, sans-serif;
  font-weight: 600;
  text-align: center;
  font-size: ${rem(30)};
  line-height: ${rem(30)};
`;

export const H4 = styled.h4`
  font-family: National-Regular, sans-serif;
  font-weight: 400;
  text-align: center;
  font-size: ${rem(24)};
  line-height: ${rem(30)};

  @media screen and (min-width: ${rem(768)}) {
    font-size: ${rem(24)};
    line-height: ${rem(28)};
  }
`;

export const Label = styled.span<{
  isActive?: boolean;
}>`
  color: #000000;
  text-transform: uppercase;
  font-family: National-Medium, sans-serif;

  font-size: ${rem(14)};

  @media screen and (min-width: ${rem(768)}) {
    font-size: ${rem(12)};
    line-height: ${rem(14)};
  }
`;

export const P = styled.p`
  color: #000000;
  line-height: ${rem(21)};
  font-family: National-Regular, sans-serif;
`;

export const A = styled.a`
  color: #f64d1c;
  text-decoration: underline;
  font-family: National-Bold, sans-serif;
  font-weight: 700;

  &:hover {
    color: #ff9677;
  }
  &:active {
    color: #cd4c27;
  }
  &:disabled {
    color: #cfcfcf;
  }
`;
