
type ImageOptions = {
    width?:  number,
    height?: number,
    gravity?: "faces",
    crop?: "fill"
}

export const constructImageUrl = (assetId: string, options: ImageOptions = {}) => {
    const basePath = process.env.REACT_APP_IMG_BASE_URL_PATH
    const params = new URLSearchParams(options as any).toString();

    return `${basePath}${assetId}?${params}`
}