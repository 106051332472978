import React from "react";
import { ErrorBoundary as ReactErrorBoundary } from "react-error-boundary";
import { ErrorPage } from "../pages/ErrorPage";

const FallbackComponent = ({ error }: { error: Error }) => {
  const message = error?.message || "";

  return <ErrorPage message={message} />;
};
export const ErrorBoundary = ({ children }: { children: React.ReactNode }) => (
  <ReactErrorBoundary FallbackComponent={FallbackComponent}>
    {children}
  </ReactErrorBoundary>
);
