import React from "react";
import styled from "@emotion/styled";

import { BasePage } from "./BasePage";
import { useTitle } from "react-use";
import { useUrlQuery } from "../helpers/token";

const defaultProps = {
  ...BasePage.defaultProps,
  H1: styled(BasePage.defaultProps.H1)`
    color: #f64d1c;
  `,
  message: "",
};

export type Props = typeof defaultProps;

export const ErrorPage = ({
  Header,
  H1,
  H3,
  H4,
  Wrapper,
  LargeLogo,
  ReturnButton,
  PageFooter,
  ContactUs,
  message,
}: Props) => {
  const title =
    "The photo slideshow is currently experiencing some complications";

  useTitle(title);

  const query = useUrlQuery();

  const errorMessage = query.get("message") || message;

  return (
    <>
      <Header />
      <Wrapper>
        <LargeLogo src="/img/Error.png" alt="Slideshow Complications" />

        <H1>{title}</H1>

        {errorMessage && <H3>{errorMessage}</H3>}
        <H4>Please try again later.</H4>

        <ReturnButton>Return to dashboard</ReturnButton>
        <ContactUs />
      </Wrapper>

      <PageFooter />
    </>
  );
};

ErrorPage.defaultProps = {
  ...defaultProps,
};
