import { Assets } from "../service/types";

import Uppy from '@uppy/core';
import AwsS3 from '@uppy/aws-s3';
import Dashboard from '@uppy/dashboard';

import '@uppy/core/dist/style.min.css';
import '@uppy/dashboard/dist/style.min.css';

import type { UppyFile, UploadResult } from '@uppy/core'

type Meta = { key: string };
type Body = {  };

export type UppyFileImage = UppyFile<Meta, Body>

export type UppyUploadError = UppyFile<Meta, Body>[]
export type UppyUploadResult = {
  event: "complete",
  info: UppyFile<Meta, Body>[]
}

export const handleUploadClick = ({
  storyId = "",
  sectionsCount = 25,
  callback
}: {
  storyId: string,
  sectionsCount: number
  callback: (
    error: UppyUploadError,
    result: UppyUploadResult
  ) => void
}) => {
  const uppy = new Uppy({
    restrictions: {
      maxFileSize: 20 * 1000000,
      allowedFileTypes: ["image/*"],
      maxNumberOfFiles: sectionsCount
    }
  }).use(Dashboard, { 
    closeModalOnClickOutside: true
  }).use(AwsS3, {
    endpoint: `${process.env.REACT_APP_S3_COMPANION_URL}/api/v1/stories/${storyId}/slideshow/`,
    headers: {
      "sf-slug": storyId,
    },
    shouldUseMultipart: false,
  });

  // @ts-ignore
  uppy.getPlugin('Dashboard').openModal()

  uppy.on('complete', (result: UploadResult<Meta, Body>) => {
    callback(result.failed, {event: 'complete', info: result.successful});
  });
};

const removeBaseUrlFromUploadUrl = (url) => {
  const parsedURL = new URL(url);
  return parsedURL.pathname.slice(1);
};

export const normalizeUppyAssets = (assets: UppyFileImage[] = []): Assets => {
  return assets.reduce((assets: Assets, asset: UppyFileImage) => {    
    if(asset.uploadURL) {
      const assetId = removeBaseUrlFromUploadUrl(asset.uploadURL)
      return {
        ...assets,
        [asset.id]: {
          assetId,
          publicId: assetId,
          format: asset.extension,
          bytes: asset.size || undefined,
          resourceType: asset.type
        }
      }
    } else {
      return assets
    }
  }, {} as Assets);
};