import React, {
  createContext,
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { getProjectFromDto, projectService } from "../service/projectService";
import { v4 } from "uuid";
import { useIsMutating, useMutation } from "@tanstack/react-query";
import throttle from "lodash/throttle";
import {
  UppyUploadError,
  UppyUploadResult,
  normalizeUppyAssets,
} from "./Uppy";
import { mapAssetsToTimelineAtIndex } from "../helpers/mapAssetsToTimelineAtIndex";
import { useBeforeUnload } from "react-use";
import { SECTION_LENGTH_IN_SECONDS } from "../helpers/constants";
import { usePlayerContext } from "./usePlayerContext";
import {
  Assets,
  Project,
  TimelineItem,
  TimelineListType,
} from "../service/types";

import { useError } from './GlobalErrorContext';
import { captureException } from "@sentry/react";

export type Context = Readonly<{
  project: Project;
  isDirty: boolean;
  timeline: TimelineListType;
  updateTimeline: React.Dispatch<React.SetStateAction<TimelineListType>>;
  assets: Assets;
  sectionsCount: number;
  activeItem: TimelineItem;
  activeIndex: number;
  addPictures: (
    error: UppyUploadError,
    result: UppyUploadResult,
    index?: number,
  ) => void;
  submittedAt?: string;
  setSubmittedAt?: (date: string) => void;
}>;

export const TimelineContext = createContext<Context>({
  project: getProjectFromDto(),
  isDirty: false,
  activeIndex: 0,
  activeItem: { id: "" },
  addPictures(): void {},
  sectionsCount: 0,
  updateTimeline(): void {},
  timeline: [],
  assets: {},
});
const throttleMs = 2000;

const generateEmptyTimeline = (n: number = 1): TimelineItem[] =>
  Array.from({ length: n }, () => ({ id: v4() }));

export const TimelineContextProvider = ({
  children,
  project,
}: PropsWithChildren<{ project: Project }>) => {
  const isMutating = useIsMutating();
  const [isDirty, setDirty] = useState(false);

  const { showError } = useError();

  useBeforeUnload(
    isDirty || isMutating > 0,
    "You have unsaved changes, are sure you want to leave the page?",
  );

  const { playedInSeconds, durationInSeconds } = usePlayerContext();
  const [timeline, setTimeline] = useState<TimelineListType>(
    project.timeline ?? [],
  );
  const prevTimeline = useRef<TimelineListType>(timeline);
  const [assets, setAssets] = useState<Assets>(project.assets ?? {});
  const [sectionsCount, setSectionsCount] = useState<number>(0);
  const [submittedAt, setSubmittedAt] = useState("");

  useEffect(() => {
    if (project?.timeline && project?.timeline?.length > 0) {
      setTimeline(project.timeline);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project.id, project?.timeline?.length, project.updatedAt]);

  const timelineLength = project.timeline?.length ?? 0;
  useEffect(() => {
    const length =
      Math.floor(durationInSeconds / SECTION_LENGTH_IN_SECONDS) ?? 0;

    if (timelineLength === 0 && length > 0) {
      setTimeline(generateEmptyTimeline(length));
    }

    if (timelineLength !== 0 && timelineLength < length) {
      setTimeline((timeline) => [
        ...timeline,
        ...generateEmptyTimeline(length - timelineLength),
      ]);
    }

    setSectionsCount(length);
  }, [timelineLength, durationInSeconds, project.timeline]);

  useEffect(() => {
    if (Object.keys(project.assets).length > 0) {
      setAssets(project.assets);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project.id, Object.keys(project.assets).length, project.updatedAt]);

  const postTimeline = useMutation(projectService.postTimeline);
  const postAssets = useMutation(projectService.postAssets);

  const throttledAddAssets = useMemo(
    () =>
      throttle((assets: Assets) => {
        setDirty(false);
        if (project.id && Object.keys(assets).length > 0) {
          postAssets.mutate({ projectId: project.id, assets });
        }
      }, throttleMs),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [project.id, throttleMs],
  );

  const throttledPutTimeline = useMemo(
    () =>
      throttle((currTimeline: TimelineListType) => {
        setDirty(false);
        const hasAssets = currTimeline.some((item) => item.assetId);
        const hadAssets = prevTimeline.current.some((item) => item.assetId);
        prevTimeline.current = currTimeline;

        if (hasAssets || (!hasAssets && hadAssets)) {
          postTimeline.mutate({
            projectId: project.id,
            timeline: currTimeline,
          });
        }
      }, throttleMs),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [project.id, throttleMs],
  );

  const updateTimeline: React.Dispatch<React.SetStateAction<TimelineListType>> =
    useCallback(
      (valueOrFn) => {
        setDirty(true);

        setTimeline((timeline) => {
          const newTimeline: TimelineListType =
            valueOrFn instanceof Function ? valueOrFn?.(timeline) : valueOrFn;
          throttledPutTimeline(newTimeline);

          return newTimeline;
        });
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [throttledPutTimeline, setTimeline, setDirty],
    );

  const addPictures = useCallback(
    (
      errors: UppyUploadError,
      result: UppyUploadResult,
      index: number = 0,
    ) => { 
      if(errors.length) {
        showError(`Failed to upload ${errors.length} images. Please try again.`, "Failure to upload")

        errors.forEach((error) => {
          captureException(error)
        })
      }

      if (result.event !== "complete") {
        return;
      }

      const uploadedAssets = normalizeUppyAssets(result.info);
      
      setDirty(true);
      setAssets((assets) => {
        const nextAssets = {
          ...assets,
          ...uploadedAssets,
        };
        
        throttledAddAssets(nextAssets);

        updateTimeline((timeline) =>
          mapAssetsToTimelineAtIndex({
            assets: uploadedAssets,
            timeline,
            index,
          }),
        );

        return nextAssets;
      });
    },
    [setAssets, updateTimeline, throttledAddAssets],
  );

  const { activeIndex, activeItem } = useMemo(() => {
    const activeIndex =
      Math.floor(playedInSeconds / SECTION_LENGTH_IN_SECONDS) % sectionsCount ||
      0;
    const activeItem = timeline[activeIndex];

    return { activeIndex, activeItem };
  }, [playedInSeconds, timeline, sectionsCount]);

  const value = useMemo(
    () => ({
      project,
      isDirty,
      timeline,
      updateTimeline,
      assets,
      sectionsCount,
      activeIndex,
      activeItem,
      addPictures,
      submittedAt,
      setSubmittedAt,
    }),
    [
      project,
      isDirty,
      timeline,
      updateTimeline,
      assets,
      sectionsCount,
      activeIndex,
      activeItem,
      addPictures,
      submittedAt,
      setSubmittedAt,
    ],
  );

  return (
    <TimelineContext.Provider value={value}>
      {children}
    </TimelineContext.Provider>
  );
};
