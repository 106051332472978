import React, { createContext, useContext, useState, ReactNode } from 'react';

interface ErrorContextType {
  errorMessage: string;
  errorHeadline?: string
  showError: (message: string, errorHeadline?: string) => void;
  hideError: () => void;
}

const ErrorContext = createContext<ErrorContextType | undefined>(undefined);

export const useError = (): ErrorContextType => {
  const context = useContext(ErrorContext);
  if (!context) {
    throw new Error('useError must be used within an ErrorProvider');
  }
  return context;
};

interface ErrorProviderProps {
  children: ReactNode;
}

export const ErrorProvider: React.FC<ErrorProviderProps> = ({ children }) => {
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [errorHeadline, setErrorHeadline] = useState<string>('');

  const showError = (message: string, errorHeadline?: string) => {
    setErrorMessage(message);
    setErrorHeadline(errorHeadline || "")
  };

  const hideError = () => {
    setErrorMessage('');
    setErrorHeadline('')
  };

  return (
    <ErrorContext.Provider value={{ errorMessage, errorHeadline, showError, hideError }}>
      {children}
    </ErrorContext.Provider>
  );
};
