import { ProjectState } from "../helpers/constants";
import { SortableAttributes } from "../components/timeline/Timeline";

export type TimelineItem = SortableAttributes & {
  id: string;
  assetId?: AssetId;
  publicId?: AssetId;
};

export enum VideoUrlHost {
  CLOUDINARY = "CLOUDINARY",
  S3 = "S3",
}

export type ProjectDto = ProjectTypeBase &
  Readonly<{
    assets: Asset[] | null;
  }>;
export type Project = ProjectTypeBase &
  Readonly<{
    assets: Assets;
  }>;
export type TimelineListType = TimelineItem[];
export type AssetId = string;
export type Asset = {
  assetId: AssetId; // "1389c34de9b0e8b5143475738545eca5",
  publicId: AssetId; // "1389c34de9b0e8b5143475738545eca5",
  format?: string; // "jpg",
  bytes?: number; //  5836,
  resourceType?: string; // "image",
};
export type Assets = Record<AssetId, Asset>;
export type TimelineItemDto = {
  assetId: string; // "d39b6ea9b17956245eac77038efd7af0"
  id: string; // "e0074bd5-47d7-4be9-a6d0-d3f164d47607"
  publicId: string; // "photo-timeline-stg/s4ueqlwmpzrda3rgt9uw"
};
export type ProjectTypeBase = {
  id: string;
  customerEmail: string;
  songTitle: string;
  artistName: string;
  songUrl: string;
  videoUrl: string | null;
  videoUrlHost: VideoUrlHost | null;
  state: ProjectState;
  createdAt: string; // "2021-03-21T12:31:28.614Z";
  updatedAt: string; // "2021-03-21T12:31:28.614Z";
  timeline: TimelineItemDto[] | null;
  remainingRenders: number;
};
