import React from "react";
import "@atlaskit/css-reset";
import { Global, css } from "@emotion/react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { ErrorProvider } from './hooks/GlobalErrorContext';
import ErrorPopup from './components/ErrorPopup';

import { TimelinePageAuthInterceptor } from "./pages/TimelinePage";
import { AdminPageAuthInterceptor } from "./pages/AdminPage";
import { InProgressPage } from "./pages/InProgressPage";
import { InRevisionPage } from "./pages/InRevisionPage";
import { NoMatchPage } from "./pages/NoMatchPage";
import { ErrorPage } from "./pages/ErrorPage";
import { AdminStateHistory } from "./pages/AdminStateHistory";
import { SessionExpiredPage } from "./pages/SessionExpiredPage";
import { NoMoreVideoRenders } from "./pages/NoMoreVideoRenders";
import { StyleguidePage } from "./pages/StyleguidePage";

import { ErrorBoundary } from "./components/ErrorBoundary";
import { Layout } from "./components/Layout";

import { ReactQueryClientProvider } from "./hooks/QueryClientContext";

export const App = () => {
  return (
    <ReactQueryClientProvider>
      <Global
        styles={css`
          html,
          body,
          #root {
            width: 100%;
            height: 100%;
            font-size: 16px;
            font-family: National-Book, sans-serif;
          }
        `}
      />
      <Layout>
        <ErrorProvider>
          <BrowserRouter>
            <Routes>
              <Route path="/">
                <Route
                  index
                  element={
                    <ErrorBoundary>
                      <TimelinePageAuthInterceptor />
                    </ErrorBoundary>
                  }
                />
                <Route path="/oops" element={<ErrorPage />} />
                <Route path="/session-expired" element={<SessionExpiredPage />} />
                <Route
                  path="/admin/project/:id/state-history"
                  element={
                    <ErrorBoundary>
                      <AdminStateHistory />
                    </ErrorBoundary>
                  }
                />

                <Route
                  path="/admin"
                  element={
                    <ErrorBoundary>
                      <AdminPageAuthInterceptor />
                    </ErrorBoundary>
                  }
                />

                <Route path="/in-progress" element={<InProgressPage />} />

                <Route path="/in-revision" element={<InRevisionPage />} />

                <Route
                  path="/no-more-video-renders"
                  element={<NoMoreVideoRenders />}
                />

                <Route path="/styleguide" element={<StyleguidePage />} />

                <Route path="*" element={<NoMatchPage />} />
              </Route>

            </Routes>
            <ErrorPopup/>

          </BrowserRouter>
          
        </ErrorProvider>
      </Layout>
      <ReactQueryDevtools initialIsOpen={false} />
    </ReactQueryClientProvider>
  );
};

export default App;
