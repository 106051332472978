import React from "react";
import styled from "@emotion/styled";
import { rem } from "polished";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlayCircle } from "@fortawesome/free-solid-svg-icons";
import { Title } from "./Title";
import { TimelineBox } from "./timeline/TimelineBox";
import { GhostButton } from "./Button";
import { useTimelineContext } from "../hooks/useTimelineContext";
import { useGetDurationBetweenContext } from "../hooks/useGetDurationBetweenContext";
import { usePlayerContext } from "../hooks/usePlayerContext";
import { constructImageUrl } from "../helpers/image"

export const VideoPreview = () => (
  <Wrapper>
    <DurationTitle />
    <Picture />
    <Audio />
  </Wrapper>
);

export const Wrapper = styled(TimelineBox)`
  cursor: pointer;
  background: #f4f4f1;
  margin: 1rem auto 1rem auto;

  audio {
    width: 100%;
  }

  // noinspection CssInvalidPseudoSelector
  audio::-webkit-media-controls-enclosure {
    border-radius: 0;
    background: white;
    box-sizing: border-box;
  }
`;

const DurationTitle = () => {
  const { activeIndex } = useTimelineContext();
  const { getDurationBetween } = useGetDurationBetweenContext();
  const durationBetween = getDurationBetween(activeIndex);

  return <Title>{durationBetween}</Title>;
};

const getPlaceholderImage = ({ text = "", resolution = "300x200" }): string =>
  `https://via.placeholder.com/${resolution}/D7D2B8/000000/?text=${encodeURI(
    text,
  )}`;

const Picture = () => {
  const { activeIndex, activeItem } = useTimelineContext();
  const { playing, togglePlaying } = usePlayerContext();

  const { getDurationBetween } = useGetDurationBetweenContext();

  const durationBetween = getDurationBetween(activeIndex);
  const nextDurationBetween = getDurationBetween(activeIndex + 1);

  const resolution = "600x400";
  const img = new Image();
  img.src = getPlaceholderImage({ text: nextDurationBetween, resolution });

  const imagePath = activeItem?.publicId ? constructImageUrl(activeItem.publicId as string, {
    width: 1200,
    height: 800,
    // gravity:"faces",
    // crop:"fill"
  }) : undefined

  return (
    <PreviewPictureWrapper onClick={togglePlaying}>
      <PlaySongButton playing={playing}>
        {!playing && <FontAwesomeIcon icon={faPlayCircle} />}
      </PlaySongButton>
      {imagePath ? (
          <img src={imagePath} alt={durationBetween}/>
      ) : (
        <img
          src={getPlaceholderImage({ text: durationBetween, resolution })}
          alt={durationBetween}
        />
      )}
    </PreviewPictureWrapper>
  );
};

export const PreviewPictureWrapper = styled(GhostButton)`
  img {
    width: 100%;
  }

  width: ${rem(270)};
  height: ${rem(180)};

  @media screen and (min-width: ${rem(321)}) {
    width: ${rem(300)};
    height: ${rem(200)};
  }

  @media screen and (min-width: ${rem(768)}) {
    width: ${rem(600)};
    height: ${rem(400)};
  }

  &:hover span {
    color: #ff9677;
  }
`;

export const PlaySongButton = styled.span<{ playing?: boolean }>`
  position: absolute;

  padding: 0;
  background: none;

  font-size: 3em;
  color: #f64f1c;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  z-index: 2;
`;

const Audio = () => {
  const { audio } = usePlayerContext();

  return <>{audio}</>;
};
