import React from "react";
import { useNavigate } from "react-router-dom";
import { ProjectTable } from "../components/AdminTable";
import styled from "@emotion/styled";
import { rem } from "polished";
import { Header } from "../components/Header";
import { Footer } from "../components/Footer";
import { ReactQueryClientProvider } from "../hooks/QueryClientContext";
import {
  isAdminTokenValid,
  isTokenValid,
  setToken,
  useUrlQuery,
} from "../helpers/token";

export const AdminPageAuthInterceptor = () => {
  const query = useUrlQuery();
  const navigate = useNavigate();
  const token = query.get("token") || "";
  if (token && isTokenValid(token)) {
    setToken({ token });
  }

  if (!isTokenValid()) {
    navigate("/session-expired");
    return null;
  }

  const isAdmin = isAdminTokenValid();

  if (!isAdmin) {
    window.location.href = process.env.REACT_APP_DASHBOARD_URL ?? "/";
    return null;
  }

  return <AdminPage />;
};

export const AdminPage = () => (
  <ReactQueryClientProvider>
    <Header />
    <Wrapper>
      <ProjectTable />
    </Wrapper>
    <Footer />
  </ReactQueryClientProvider>
);

const Wrapper = styled.div`
  @media screen and (min-width: ${rem(1200)}) {
    margin: 0 auto;
    max-width: ${rem(1200)};
  }
  width: 100%;
`;
