import React from 'react';
import styled from "@emotion/styled";
import { useError } from '../hooks/GlobalErrorContext';

const Popup = styled.div`
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 1000;
  width: 353px;
  padding: 20px;
  border-radius: 16px;
  background-color: #E9044B;
  color: white;
  box-shadow: 0px 25px 20px -15px rgba(0, 0, 0, 0.30);
`;

const Message = styled.div`
  display: grid;
  grid-template-columns: 1fr 24px;
  column-gap: 10px;
`;




const CloseButtonWrapper = styled.div`
    grid-column: 2;
    grid-row: span 2;
    width: 24px;
    height: 24px;
`;

const CloseButton = styled.button`
    background-color: transparent;
    color: white;
    cursor: pointer;
    border: none;
    padding: 0;
    margin: 0;
`;

const ErrorHeadline = styled.p`
    grid-column: 1;
    font-family: National-Regular, sans-serif;
    font-variant-numeric: lining-nums proportional-nums;
    font-feature-settings: 'liga' off, 'clig' off;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.27px;
`

const ErrorMessage = styled.p`
    grid-column: 1;
    font-family: National, sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 380;
    line-height: normal;
`

const ErrorPopup: React.FC = () => {
  const { errorMessage, errorHeadline, hideError } = useError();

  if (!errorMessage) return null;

  return (
    <Popup>
      <Message>
        {!!errorHeadline && (
          <ErrorHeadline>{errorHeadline}</ErrorHeadline>
        )}
        <ErrorMessage>{errorMessage}</ErrorMessage>
        <CloseButtonWrapper>
          <CloseButton onClick={hideError}>
              <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path d="m17 17-5-5m0 0L7 7m5 5 5-5m-5 5-5 5" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
          </CloseButton>
        </CloseButtonWrapper>
      </Message>
    </Popup>
  );
};

export default ErrorPopup;
